<template>
  <v-main class="mt-5">
    <div class="mb-5 mt-5">
      <h3 class="mb-5 pt-5">User List</h3>

      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div v-if="loading">Loading...</div>
      <v-btn class="mr-4" to="/usercreate"> Create User </v-btn>
      <downloadexcel
        class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default"
        :fetch="fetchData"
        :fields="json_fields"
        name="MiladyUserList.xls"
        :before-generate="startDownload"
        :before-finish="finishDownload"
      >
        Download Excel
      </downloadexcel>

      <v-expansion-panels class="mt-5 mb-5 purple lighten-5">
        <v-expansion-panel class="purple lighten-5">
          <v-expansion-panel-header> Search Panel </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-text-field
                class="mt-4"
                placeholder="Search User"
                v-model="searchQuery"
                prepend-icon="mdi-magnify"
                @change="findUser(searchQuery)"
                clearable
              >
                Search User
              </v-text-field>
              <v-col>
                <v-select
                  :items="userTypes"
                  item-text="name"
                  item-value="value"
                  label="User Type"
                  v-model="n_userType"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="genderTypes"
                  item-text="name"
                  item-value="value"
                  label="Gender"
                  v-model="n_gender"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="maritalStatusTypes"
                  item-text="name"
                  item-value="value"
                  label="Marital Status"
                  v-model="n_marital_status"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="ageList"
                  item-text="name"
                  item-value="value"
                  label="Age"
                  v-model="n_age"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="countries"
                  item-text="name"
                  item-value="value"
                  label="Country"
                  v-model="n_country_code"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="stateRegions"
                  item-text="name"
                  item-value="value"
                  label="State Region"
                  v-model="n_state_region"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="fromDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fromDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(fromDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="toDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(toDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
                <v-col cols="12" sm="3">
                <v-select
                  :items="pYearList"
                  item-text="name"
                  item-value="value"
                  label="Year"
                  v-model="nYear"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-btn @click="getUserDataFromApi()">Search</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-circular
        :size="30"
        v-show="showProgress"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <!-- <v-data-table
        :headers="headers"
        :items="filterUser"
        :items-per-page="20"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>User List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="showResetPassword(item.id,item.phone)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="showDetail(item.id,item.user_type)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>-->
      <v-layout child-flex>
        <v-data-table
          dense
          :headers="headers"
          :items="userList"
          :options.sync="userListOptions"
          :server-items-length="totalUsers"
          :loading="userListLoading"
          loading-text="Loading... Please wait"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="showResetPassword(item.id, item.phone)"
            >
              mdi-key
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="showDetail(item.id, item.user_type)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-layout>
      <v-dialog v-model="resetPasswordDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="setPasswordData.phoneno"
                    label="PhoneNo*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="setPasswordData.pass"
                    label="Password*"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="resetPasswordDialog = false"
            >
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="setUserPassword()">
              Reset
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-show="snackbar" color="responseColor" v-model="snackbar">
        {{ responseText }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_USERLIST, SET_PASSWORD } from "../store/actions.type";
import {countryList,userTypes,providerTypes, genderTypes,ageList,maritalStatusTypes,stateRegions, pYears}  from "../common/ref_data";
import downloadexcel from "vue-json-excel";
export default {
  name: "UserList",
  components: {
    downloadexcel,
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      userTypes: userTypes,
      ageList: ageList,
      genderTypes: genderTypes,
      maritalStatusTypes: maritalStatusTypes,
      stateRegions: stateRegions,
      providerTypes: providerTypes,
      userListLoading: true,
      userListOptions: {},
      resetPasswordDialog: false,
      showProgress: false,
      userType: "",
      userList: [],
      totalUsers: 0,
      searchQuery: "",
      info: null,
      loading: true,
      errored: false,
      snackbar: false,
      responseColor: "success",
      responseText: "",
      n_gender: null,
      n_marital_status: null,
      n_age: null,
      n_state_region: null,
      n_country_code: null,
      n_userType: null,
      fromDate: null,
      toDate: null,
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "serialno",
        },
        {
          text: "User ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Register Date", value: "createddate" },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Gender", value: "gender" },
        { text: "Age", value: "age" },
        { text: "Marital Status", value: "marital_stcatus" },
        { text: "User Type", value: "user_type" },
        { text: "Provider Type", value: "provider_type" },
        { text: "Organization", value: "organization" },
        { text: "Country", value: "country_code" },
        { text: "State Region", value: "state_region" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      setPasswordData: {
        uid: null,
        pass: null,
        phoneno: null,
      },
      pYearList: pYears,
      countries: countryList,
      userListParam: {
        type: "",
        search: "",
        page: 0,
        limit: 20,
        sortBy: [],
        sortDesc: [],
      },
      json_fields: {
        UserID: "id",
        Name: "name",
        MobileNo: "phone",
        Gender: "gender",
        Age: "age",
        MaritalStatus: "marital_status",
        StateRegion: "state_region",
        CountryCode: "country_code",
        UserType: "user_type",
        ProviderType: "provider_type",
        Organization: "organization",
        IsActive: "isactive",
        CreatedDate: "createddate",
        Updateddate: "updateddate",
        Isdeleted: "isdeleted",
        Deleted_Date: "deleteddate",
      },
    };
  },
  watch: {
    userListOptions: {
      handler() {
        this.getUserDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
    userListWithSerialNo() {
      return this.userList.map((d, index) => ({ ...d, serialno: index + 1 }));
    },
  },
  created() {},
  methods: {
    fetchData() {
      this.showProgress = true;
      return this.userList;
    },
    startDownload() {
      this.showProgress = true;
    },
    finishDownload() {
      this.showProgress = false;
    },
    getUserDataFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.userListOptions;
      this.userListParam.page = page;
      this.userListParam.limit = itemsPerPage;
      this.userListParam.sortBy = sortBy;
      this.userListParam.sortDesc = sortDesc;
      this.userListParam.option = {
        user_type: this.n_userType,
        age: this.n_age,
        country_code: this.n_country_code,
        state_region: this.n_state_region,
        gender: this.n_gender,
        marital_status: this.n_marital_status,
        year: this.nYear,
        from_date: this.fromDate,
        to_date: this.toDate,
      };
      this.userListLoading = true;
      console.log(this.userListParam);
      this.$store
        .dispatch(FETCH_USERLIST, this.userListParam)
        .then((data) => {
          this.userList = data.users;
          this.totalUsers = data.total;
          this.userListLoading = false;
          this.loading = false;
          console.log(this.userList);
        })
        .catch(() => {
          this.userListLoading = false;
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.userListLoading = false));
    },
    findUser(searchString) {
      this.userListParam.search = searchString;
      this.getUserDataFromApi();
    },
    setUserPassword() {
      this.$store
        .dispatch(SET_PASSWORD, this.setPasswordData)
        .then((data) => {
          if (data.status == 0) {
            this.snackbar = true;
            this.responseColor = "green";
            this.responseText = data.message;
            this.resetPasswordForm();
          } else {
            this.resetPasswordForm();
            this.snackbar = true;
            this.responseColor = "red";
            this.responseText = data.message;
          }
        })
        .catch(() => {
          this.resetPasswordForm();
          this.userListLoading = false;
          this.loading = false;
          this.errored = true;
          this.snackbar = true;
          this.responseColor = "red";
          this.responseText = "Failed to Update Password";
        })
        .finally(
          () => ((this.userListLoading = false), this.resetPasswordForm())
        );
    },
    resetPasswordForm() {
      this.resetPasswordDialog = false;
      this.setPasswordData = {
        uid: null,
        pass: null,
        phoneno: null,
      };
    },
    showResetPassword(userID, phoneNo) {
      this.setPasswordData.uid = userID;
      this.setPasswordData.phoneno = phoneNo;
      this.resetPasswordDialog = true;
    },
    showDetail(userID, userType) {
      localStorage.setItem("selectedUser", userID);
      if (userType == "N") {
        this.$router.push({
          name: "UserDetail",
          params: {
            userID: userID,
          },
        });
      } else {
        this.$router.push({
          name: "ProviderDetail",
          params: {
            userID: userID,
          },
        });
      }
    },
  },
};
</script>
